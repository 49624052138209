@media print {
  pulse-sidebar-nav,
  pulse-toolbar,
  pulse-resource-header .view-container {
    display: none;
  }

  pulse-project {
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
  }

  pulse-pulse,
  .main-content {
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
  }

  pulse-resource-card-view {
    .icon-arrow_up {
      display: block;
    }

    .icon-arrow_right {
      display: none;
    }

    .add-prop-container {
      display: block;
    }
  }

  cdk-row cdk-cell:last-child,
  cdk-header-row cdk-header-cell:last-child {
    display: none;
  }


  cdk-row cdk-cell:first-child,
  cdk-header-row cdk-header-cell:first-child {
    width: 100%;
  }

  cdk-row cdk-cell:not(:first-child),
  cdk-header-row cdk-header-cell:not(:first-child) {
    flex: 1;
  }

  .cdk-overlay-container {
    .icon-close, .print-button, .role-buttons pulse-button {
      display: none;
    }
  }
}
