@import './layout/css-variables';
@import 'init';
@import './layout/grid';
@import './layout/header';
@import './components/input';
@import './base/icons';
@import '@angular/cdk/overlay-prebuilt.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import './print.scss';
@import '~handsontable/dist/handsontable.full.css';
@import './components/custom-handsontable';
@import '../../../../../libs/ui/src/text-area/styles/global-quill-editor-styles';

@font-face {
  font-display: swap;
  font-family: 'Siemens Sans';
  font-weight: 400;
  src: local('Siemens Sans Roman'), url('../fonts/SiemensSans_Prof_Roman.woff2') format('woff2'),
    url('../fonts/SiemensSans_Prof_Roman.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Siemens Sans';
  font-weight: 700;
  src: local('Siemens Sans Bold'), url('../fonts/SiemensSans_Prof_Bold.woff2') format('woff2'),
    url('../fonts/SiemensSans_Prof_Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Siemens Sans';
  font-weight: 900;
  src: local('Siemens Sans Black'), url('../fonts/SiemensSans_Prof_Black.woff2') format('woff2'),
    url('../fonts/SiemensSans_Prof_Black.woff') format('woff');
}

html {
  font-family: $body-font-family;
  height: 100%;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    outline: 0;
    font-family: inherit;
  }
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: $body-font-size;
  font-family: $body-font-family;
  font-weight: $body-font-weight;
  color: $body-text-color;
}

* {
  @include scrollbar(6px, var(--primary02), var(--disabled));
}

a {
  color: var(--textPrimary04);
  text-decoration: var(--primary01);
  cursor: pointer;
  > label {
    cursor: pointer;
  }
  &:hover {
    color: var(--hoverPrimary01);
    text-decoration: var(--primary01);
  }
}

b {
  font-weight: $b-font-weight;
}

sub {
  vertical-align: text-bottom; /* <-- Reset the user agent stylesheet   */
  position: relative; /* <-- Set position to the element       */
  top: 0.2em; /* <-- Move the <sub> element down a bit */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.content-card-light {
  background: $light-color;
  padding: 25px;
  margin-bottom: 20px;
}

.content-card-dark {
  background: $dark-color;
  padding: 25px;
  margin-bottom: 20px;
}

@each $h, $value in $headlines {
  #{$h},
  .#{$h} {
    @include headline(
      map-get($value, font-size),
      map-get($value, font-weight),
      map-get($value, line-height),
      map-get($value, margin)
    );
  }
}

.sub-headline {
  font-size: $h-sub-font-size;
  font-weight: $h-sub-font-weight;
  text-transform: $h-sub-text-transform;
  color: $h-sub-color;
  margin: $h-sub-margin;
}

@each $color, $value in $theme-colors {
  .color-#{$color} {
    color: $value;
  }
}

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background-color: $value;
  }
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

.invisible {
  visibility: hidden;
}

.wrap {
  margin: 20px;
}

@each $wrap in top, bottom, left, right {
  .wrap-#{$wrap} {
    margin-#{$wrap}: 20px;
  }
}

.highchart-bar-color {
  color: $primary-color;
  fill: $primary-color;
  stroke: $primary-color;

  &.highcharts-point-hover {
    color: $sidebar-nav-highlight-color;
    fill: $sidebar-nav-highlight-color;
    stroke: $primary-color;
  }
}

.survey-dashboard-column {
  width: 100%;
}

.theme-color {
  color: $primary-color;
}

// tetris-core {
//   box-sizing: unset;
//   height: 90%;
//   board {
//     width: 50%;
//     height: auto;
//   }
// }

/* clears the 'X' in inputs from Internet Explorer */
input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.dont-print {
  display: inherit;
}

@media print {
  .dont-print {
    display: none !important;
  }
}

// Overwrite the css class of the Material Angular virtual scroll content wrapper
cdk-virtual-scroll-viewport > .cdk-virtual-scroll-content-wrapper {
  position: relative;
  contain: unset;
}
