@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?p3r07k');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?p3r07k#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?p3r07k') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?p3r07k') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?p3r07k##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-message {
  &:before {
    content: $icon-message;
  }
}
.icon-mic_none {
  &:before {
    content: $icon-mic_none;
  }
}
.icon-mic_off {
  &:before {
    content: $icon-mic_off;
  }
}
.icon-mic {
  &:before {
    content: $icon-mic;
  }
}
.icon-mobile_erase {
  &:before {
    content: $icon-mobile_erase;
  }
}
.icon-mobile_landscape {
  &:before {
    content: $icon-mobile_landscape;
  }
}
.icon-mobile_lock {
  &:before {
    content: $icon-mobile_lock;
  }
}
.icon-home2 {
  &:before {
    content: $icon-home2;
  }
}
.icon-network {
  &:before {
    content: $icon-network;
  }
}
.icon-organization {
  &:before {
    content: $icon-organization;
  }
}
.icon-projectview {
  &:before {
    content: $icon-projectview;
  }
}
.icon-favorite_outline {
  &:before {
    content: $icon-favorite_outline;
  }
}
.icon-settings_multiple {
  &:before {
    content: $icon-settings_multiple;
  }
}
.icon-upgrade {
  &:before {
    content: $icon-upgrade;
  }
}
.icon-mobile_portrait {
  &:before {
    content: $icon-mobile_portrait;
  }
}
.icon-mobile_setup {
  &:before {
    content: $icon-mobile_setup;
  }
}
.icon-mode_comment {
  &:before {
    content: $icon-mode_comment;
  }
}
.icon-euro {
  &:before {
    content: $icon-euro;
  }
}
.icon-more_horizontal {
  &:before {
    content: $icon-more_horizontal;
  }
}
.icon-more_vertical {
  &:before {
    content: $icon-more_vertical;
  }
}
.icon-move_to_inbox {
  &:before {
    content: $icon-move_to_inbox;
  }
}
.icon-gift {
  &:before {
    content: $icon-gift;
  }
}
.icon-not_interested {
  &:before {
    content: $icon-not_interested;
  }
}
.icon-notifications_none {
  &:before {
    content: $icon-notifications_none;
  }
}
.icon-notifications_off {
  &:before {
    content: $icon-notifications_off;
  }
}
.icon-notifications {
  &:before {
    content: $icon-notifications;
  }
}
.icon-outlined_flag {
  &:before {
    content: $icon-outlined_flag;
  }
}
.icon-pause_circle_filled {
  &:before {
    content: $icon-pause_circle_filled;
  }
}
.icon-company {
  &:before {
    content: $icon-company;
  }
}
.icon-circle_outline {
  &:before {
    content: $icon-circle_outline;
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock;
  }
}
.icon-charging {
  &:before {
    content: $icon-charging;
  }
}
.icon-rates {
  &:before {
    content: $icon-rates;
  }
}
.icon-gross_costs {
  &:before {
    content: $icon-gross_costs;
  }
}
.icon-quality {
  &:before {
    content: $icon-quality;
  }
}
.icon-settings_single {
  &:before {
    content: $icon-settings_single;
  }
}
.icon-savings {
  &:before {
    content: $icon-savings;
  }
}
.icon-pause_circle_outline {
  &:before {
    content: $icon-pause_circle_outline;
  }
}
.icon-tiles {
  &:before {
    content: $icon-tiles;
  }
}
.icon-pause {
  &:before {
    content: $icon-pause;
  }
}
.icon-reset {
  &:before {
    content: $icon-reset;
  }
}
.icon-person_check {
  &:before {
    content: $icon-person_check;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-pie_chart {
  &:before {
    content: $icon-pie_chart;
  }
}
.icon-play_arrow {
  &:before {
    content: $icon-play_arrow;
  }
}
.icon-play_circle_filled {
  &:before {
    content: $icon-play_circle_filled;
  }
}
.icon-play_circle_outline {
  &:before {
    content: $icon-play_circle_outline;
  }
}
.icon-playlist_add_check {
  &:before {
    content: $icon-playlist_add_check;
  }
}
.icon-playlist_add {
  &:before {
    content: $icon-playlist_add;
  }
}
.icon-power {
  &:before {
    content: $icon-power;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-priority_high {
  &:before {
    content: $icon-priority_high;
  }
}
.icon-radio_button_checked {
  &:before {
    content: $icon-radio_button_checked;
  }
}
.icon-recent_actors {
  &:before {
    content: $icon-recent_actors;
  }
}
.icon-analyze {
  &:before {
    content: $icon-analyze;
  }
}
.icon-repeat_one {
  &:before {
    content: $icon-repeat_one;
  }
}
.icon-repeat {
  &:before {
    content: $icon-repeat;
  }
}
.icon-replay {
  &:before {
    content: $icon-replay;
  }
}
.icon-save_alt {
  &:before {
    content: $icon-save_alt;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-save {
  &:before {
    content: $icon-save;
  }
}
.icon-screen_rotation {
  &:before {
    content: $icon-screen_rotation;
  }
}
.icon-investment {
  &:before {
    content: $icon-investment;
  }
}
.icon-security {
  &:before {
    content: $icon-security;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
  }
}
.icon-quotation {
  &:before {
    content: $icon-quotation;
  }
}
.icon-sent {
  &:before {
    content: $icon-sent;
  }
}
.icon-send {
  &:before {
    content: $icon-send;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-show_chart {
  &:before {
    content: $icon-show_chart;
  }
}
.icon-skip_next {
  &:before {
    content: $icon-skip_next;
  }
}
.icon-skip_previous {
  &:before {
    content: $icon-skip_previous;
  }
}
.icon-sort {
  &:before {
    content: $icon-sort;
  }
}
.icon-star_outline {
  &:before {
    content: $icon-star_outline;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-swap_horiz {
  &:before {
    content: $icon-swap_horiz;
  }
}
.icon-swap_vert {
  &:before {
    content: $icon-swap_vert;
  }
}
.icon-tab {
  &:before {
    content: $icon-tab;
  }
}
.icon-text_format {
  &:before {
    content: $icon-text_format;
  }
}
.icon-text_sms {
  &:before {
    content: $icon-text_sms;
  }
}
.icon-thumb_down_alt {
  &:before {
    content: $icon-thumb_down_alt;
  }
}
.icon-thumb_down {
  &:before {
    content: $icon-thumb_down;
  }
}
.icon-thumb_up_alt {
  &:before {
    content: $icon-thumb_up_alt;
  }
}
.icon-thumb_up {
  &:before {
    content: $icon-thumb_up;
  }
}
.icon-thumbs_up_down {
  &:before {
    content: $icon-thumbs_up_down;
  }
}
.icon-today {
  &:before {
    content: $icon-today;
  }
}
.icon-toggle_off {
  &:before {
    content: $icon-toggle_off;
  }
}
.icon-toggle_on {
  &:before {
    content: $icon-toggle_on;
  }
}
.icon-translate {
  &:before {
    content: $icon-translate;
  }
}
.icon-tree {
  &:before {
    content: $icon-tree;
  }
}
.icon-trending_down {
  &:before {
    content: $icon-trending_down;
  }
}
.icon-trending_up {
  &:before {
    content: $icon-trending_up;
  }
}
.icon-unarchive {
  &:before {
    content: $icon-unarchive;
  }
}
.icon-update {
  &:before {
    content: $icon-update;
  }
}
.icon-video_collection {
  &:before {
    content: $icon-video_collection;
  }
}
.icon-videocam_off {
  &:before {
    content: $icon-videocam_off;
  }
}
.icon-videocam {
  &:before {
    content: $icon-videocam;
  }
}
.icon-view_list {
  &:before {
    content: $icon-view_list;
  }
}
.icon-view_module {
  &:before {
    content: $icon-view_module;
  }
}
.icon-volume_down {
  &:before {
    content: $icon-volume_down;
  }
}
.icon-volume_mute {
  &:before {
    content: $icon-volume_mute;
  }
}
.icon-volume_off {
  &:before {
    content: $icon-volume_off;
  }
}
.icon-volume_up {
  &:before {
    content: $icon-volume_up;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-workspaces {
  &:before {
    content: $icon-workspaces;
  }
}
.icon-world {
  &:before {
    content: $icon-world;
  }
}
.icon-zoom_in {
  &:before {
    content: $icon-zoom_in;
  }
}
.icon-zoom_out_map {
  &:before {
    content: $icon-zoom_out_map;
  }
}
.icon-zoom_out {
  &:before {
    content: $icon-zoom_out;
  }
}
.icon-account_balance {
  &:before {
    content: $icon-account_balance;
  }
}
.icon-account_box {
  &:before {
    content: $icon-account_box;
  }
}
.icon-account_circle {
  &:before {
    content: $icon-account_circle;
  }
}
.icon-add_box {
  &:before {
    content: $icon-add_box;
  }
}
.icon-add_call {
  &:before {
    content: $icon-add_call;
  }
}
.icon-add_circle_outlinecontrol_point {
  &:before {
    content: $icon-add_circle_outlinecontrol_point;
  }
}
.icon-add_circle {
  &:before {
    content: $icon-add_circle;
  }
}
.icon-add_comment {
  &:before {
    content: $icon-add_comment;
  }
}
.icon-add_to_queue {
  &:before {
    content: $icon-add_to_queue;
  }
}
.icon-adduser {
  &:before {
    content: $icon-adduser;
  }
}
.icon-apps {
  &:before {
    content: $icon-apps;
  }
}
.icon-archive {
  &:before {
    content: $icon-archive;
  }
}
.icon-arrow_back {
  &:before {
    content: $icon-arrow_back;
  }
}
.icon-arrow_down {
  &:before {
    content: $icon-arrow_down;
  }
}
.icon-arrow_forward {
  &:before {
    content: $icon-arrow_forward;
  }
}
.icon-arrow_left {
  &:before {
    content: $icon-arrow_left;
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right;
  }
}
.icon-arrow_up {
  &:before {
    content: $icon-arrow_up;
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment;
  }
}
.icon-backspace {
  &:before {
    content: $icon-backspace;
  }
}
.icon-bar_chart_1 {
  &:before {
    content: $icon-bar_chart_1;
  }
}
.icon-bar_chart {
  &:before {
    content: $icon-bar_chart;
  }
}
.icon-block {
  &:before {
    content: $icon-block;
  }
}
.icon-bolt {
  &:before {
    content: $icon-bolt;
  }
}
.icon-bookmark_outlineturned_in_not {
  &:before {
    content: $icon-bookmark_outlineturned_in_not;
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark;
  }
}
.icon-bubble_chart {
  &:before {
    content: $icon-bubble_chart;
  }
}
.icon-cake {
  &:before {
    content: $icon-cake;
  }
}
.icon-calendar_empty {
  &:before {
    content: $icon-calendar_empty;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-call {
  &:before {
    content: $icon-call;
  }
}
.icon-capslock {
  &:before {
    content: $icon-capslock;
  }
}
.icon-chat_bubble_outline {
  &:before {
    content: $icon-chat_bubble_outline;
  }
}
.icon-chat_bubble {
  &:before {
    content: $icon-chat_bubble;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-check_box_outline_blank {
  &:before {
    content: $icon-check_box_outline_blank;
  }
}
.icon-check_box {
  &:before {
    content: $icon-check_box;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-cloud_done {
  &:before {
    content: $icon-cloud_done;
  }
}
.icon-cloud_download {
  &:before {
    content: $icon-cloud_download;
  }
}
.icon-cloud_queue {
  &:before {
    content: $icon-cloud_queue;
  }
}
.icon-cloud_upload {
  &:before {
    content: $icon-cloud_upload;
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud;
  }
}
.icon-code {
  &:before {
    content: $icon-code;
  }
}
.icon-color_palette {
  &:before {
    content: $icon-color_palette;
  }
}
.icon-comment {
  &:before {
    content: $icon-comment;
  }
}
.icon-contacts {
  &:before {
    content: $icon-contacts;
  }
}
.icon-content_copy {
  &:before {
    content: $icon-content_copy;
  }
}
.icon-content_cut {
  &:before {
    content: $icon-content_cut;
  }
}
.icon-createmode_editedit {
  &:before {
    content: $icon-createmode_editedit;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-data_usage {
  &:before {
    content: $icon-data_usage;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-description {
  &:before {
    content: $icon-description;
  }
}
.icon-donut_chart {
  &:before {
    content: $icon-donut_chart;
  }
}
.icon-drafts {
  &:before {
    content: $icon-drafts;
  }
}
.icon-error_outline {
  &:before {
    content: $icon-error_outline;
  }
}
.icon-error {
  &:before {
    content: $icon-error;
  }
}
.icon-event_available {
  &:before {
    content: $icon-event_available;
  }
}
.icon-event_busy {
  &:before {
    content: $icon-event_busy;
  }
}
.icon-file_copy {
  &:before {
    content: $icon-file_copy;
  }
}
.icon-file_download {
  &:before {
    content: $icon-file_download;
  }
}
.icon-file_upload {
  &:before {
    content: $icon-file_upload;
  }
}
.icon-file {
  &:before {
    content: $icon-file;
  }
}
.icon-filter_list_alt {
  &:before {
    content: $icon-filter_list_alt;
  }
}
.icon-filter_list {
  &:before {
    content: $icon-filter_list;
  }
}
.icon-find_in_page {
  &:before {
    content: $icon-find_in_page;
  }
}
.icon-flagassistant_photo {
  &:before {
    content: $icon-flagassistant_photo;
  }
}
.icon-folder_favorite {
  &:before {
    content: $icon-folder_favorite;
  }
}
.icon-folder_new {
  &:before {
    content: $icon-folder_new;
  }
}
.icon-folder_open {
  &:before {
    content: $icon-folder_open;
  }
}
.icon-folder_shared {
  &:before {
    content: $icon-folder_shared;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;
  }
}
.icon-forum_answer {
  &:before {
    content: $icon-forum_answer;
  }
}
.icon-fullscreen_exit {
  &:before {
    content: $icon-fullscreen_exit;
  }
}
.icon-fullscreen {
  &:before {
    content: $icon-fullscreen;
  }
}
.icon-group_add {
  &:before {
    content: $icon-group_add;
  }
}
.icon-group {
  &:before {
    content: $icon-group;
  }
}
.icon-help_outline {
  &:before {
    content: $icon-help_outline;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-import_export {
  &:before {
    content: $icon-import_export;
  }
}
.icon-inbox {
  &:before {
    content: $icon-inbox;
  }
}
.icon-info_outline {
  &:before {
    content: $icon-info_outline;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-input {
  &:before {
    content: $icon-input;
  }
}
.icon-key {
  &:before {
    content: $icon-key;
  }
}
.icon-layers_clear {
  &:before {
    content: $icon-layers_clear;
  }
}
.icon-layers {
  &:before {
    content: $icon-layers;
  }
}
.icon-library_addqueueadd_to_photos {
  &:before {
    content: $icon-library_addqueueadd_to_photos;
  }
}
.icon-library_books {
  &:before {
    content: $icon-library_books;
  }
}
.icon-location_off {
  &:before {
    content: $icon-location_off;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-loopsync {
  &:before {
    content: $icon-loopsync;
  }
}
.icon-low_priority {
  &:before {
    content: $icon-low_priority;
  }
}
.icon-mail_unread {
  &:before {
    content: $icon-mail_unread;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-check_box_indeterminate {
  &:before {
    content: $icon-check_box_indeterminate;
  }
}
.icon-eye-blocked {
  &:before {
    content: $icon-eye-blocked;
  }
}
.icon-arrow_left_double {
  &:before {
    content: $icon-arrow_left_double;
  }
}
.icon-arrow_right_double {
  &:before {
    content: $icon-arrow_right_double;
  }
}
.icon-arrow_down_double {
  &:before {
    content: $icon-arrow_down_double;
  }
}
.icon-arrow_top_double {
  &:before {
    content: $icon-arrow_top_double;
  }
}
.icon-responserate {
  &:before {
    content: $icon-responserate;
  }
}
.icon-lines {
  &:before {
    content: $icon-lines;
  }
}
.icon-detailview {
  &:before {
    content: $icon-detailview;
  }
}
.icon-lp_gruppe {
  &:before {
    content: $icon-lp_gruppe;
  }
}
.icon-lp_forum {
  &:before {
    content: $icon-lp_forum;
  }
}
.icon-lp_email {
  &:before {
    content: $icon-lp_email;
  }
}
.icon-lp_dashboard {
  &:before {
    content: $icon-lp_dashboard;
  }
}
.icon-lp_checkmark_outline {
  &:before {
    content: $icon-lp_checkmark_outline;
  }
}
.icon-lp_chart_multitype {
  &:before {
    content: $icon-lp_chart_multitype;
  }
}
.icon-lp_category {
  &:before {
    content: $icon-lp_category;
  }
}
.icon-lp_bx_mobile_alt {
  &:before {
    content: $icon-lp_bx_mobile_alt;
  }
}
.icon-lp_bx_layer {
  &:before {
    content: $icon-lp_bx_layer;
  }
}
.icon-lp_bx_data {
  &:before {
    content: $icon-lp_bx_data;
  }
}
.icon-lp_cloud_upload {
  &:before {
    content: $icon-lp_cloud_upload;
  }
}
.icon-lp_cloud_download {
  &:before {
    content: $icon-lp_cloud_download;
  }
}
.icon-lp_face_satisfied {
  &:before {
    content: $icon-lp_face_satisfied;
  }
}
.icon-lp_misuse_outline {
  &:before {
    content: $icon-lp_misuse_outline;
  }
}
.icon-lp_face_neutral {
  &:before {
    content: $icon-lp_face_neutral;
  }
}
.icon-lp_information {
  &:before {
    content: $icon-lp_information;
  }
}
.icon-lp_warning {
  &:before {
    content: $icon-lp_warning;
  }
}
.icon-lp_search {
  &:before {
    content: $icon-lp_search;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-reportingline-business {
  &:before {
    content: $icon-reportingline-business;
  }
}
.icon-reportingline-shared {
  &:before {
    content: $icon-reportingline-shared;
  }
}
.icon-reportingline-local {
  &:before {
    content: $icon-reportingline-local;
  }
}
.icon-reportingline-governance {
  &:before {
    content: $icon-reportingline-governance;
  }
}
.icon-person-outline {
  &:before {
    content: $icon-person-outline;
  }
}
.icon-fire {
  &:before {
    content: $icon-fire;
  }
}
.icon-request {
  &:before {
    content: $icon-request;
  }
}
.icon-reporting {
  &:before {
    content: $icon-reporting;
  }
}
.icon-target {
  &:before {
    content: $icon-target;
  }
}
.icon-files {
  &:before {
    content: $icon-files;
  }
}
.icon-close2 {
  &:before {
    content: $icon-close2;
  }
}
.icon-bar-chart {
  &:before {
    content: $icon-bar-chart;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-check-mark {
  &:before {
    content: $icon-check-mark;
  }
}
.icon-home3 {
  &:before {
    content: $icon-home3;
  }
}
.icon-info2 {
  &:before {
    content: $icon-info2;
  }
}
.icon-free {
  &:before {
    content: $icon-free;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-newsletter {
  &:before {
    content: $icon-newsletter;
  }
}
.icon-business {
  &:before {
    content: $icon-business;
  }
}
.icon-idea {
  &:before {
    content: $icon-idea;
  }
}
.icon-car {
  &:before {
    content: $icon-car;
  }
}
.icon-building {
  &:before {
    content: $icon-building;
  }
}
.icon-check-mark2 {
  &:before {
    content: $icon-check-mark2;
  }
}
.icon-language {
  &:before {
    content: $icon-language;
  }
}
.icon-strategy {
  &:before {
    content: $icon-strategy;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload;
  }
}
.icon-xing {
  &:before {
    content: $icon-xing;
  }
}
.icon-efficient {
  &:before {
    content: $icon-efficient;
  }
}
.icon-money {
  &:before {
    content: $icon-money;
  }
}
.icon-dashboard2 {
  &:before {
    content: $icon-dashboard2;
  }
}
.icon-burger {
  &:before {
    content: $icon-burger;
  }
}
.icon-pulse {
  &:before {
    content: $icon-pulse;
  }
}
.icon-protected-file {
  &:before {
    content: $icon-protected-file;
  }
}
.icon-back {
  &:before {
    content: $icon-back;
  }
}
.icon-expand-left {
  &:before {
    content: $icon-expand-left;
  }
}
.icon-receive {
  &:before {
    content: $icon-receive;
  }
}
.icon-expand-right {
  &:before {
    content: $icon-expand-right;
  }
}
.icon-analyze-active {
  &:before {
    content: $icon-analyze-active;
  }
}
.icon-choice-grid {
  &:before {
    content: $icon-choice-grid;
  }
}
.icon-comment2 {
  &:before {
    content: $icon-comment2;
  }
}
.icon-disclaimer {
  &:before {
    content: $icon-disclaimer;
  }
}
.icon-drag {
  &:before {
    content: $icon-drag;
  }
}
.icon-duplicate {
  &:before {
    content: $icon-duplicate;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-hybrid-grid {
  &:before {
    content: $icon-hybrid-grid;
  }
}
.icon-information {
  &:before {
    content: $icon-information;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart;
  }
}
.icon-resource-management {
  &:before {
    content: $icon-resource-management;
  }
}
.icon-show {
  &:before {
    content: $icon-show;
  }
}
.icon-sort2 {
  &:before {
    content: $icon-sort2;
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link;
  }
}
.icon-filter-active {
  &:before {
    content: $icon-filter-active;
  }
}
.icon-menu1 {
  &:before {
    content: $icon-menu1;
  }
}
.icon-finding {
  &:before {
    content: $icon-finding;
  }
}
.icon-arrowdown {
  &:before {
    content: $icon-arrowdown;
  }
}
.icon-arrowup {
  &:before {
    content: $icon-arrowup;
  }
}
.icon-downloadpdf {
  &:before {
    content: $icon-downloadpdf;
  }
}
.icon-remind {
  &:before {
    content: $icon-remind;
  }
}
.icon-inviteuser {
  &:before {
    content: $icon-inviteuser;
  }
}
.icon-invite-user {
  &:before {
    content: $icon-invite-user;
  }
}
.icon-medium {
  &:before {
    content: $icon-medium;
  }
}
.icon-downloadcsv {
  &:before {
    content: $icon-downloadcsv;
  }
}
.icon-download-csv {
  &:before {
    content: $icon-download-csv;
  }
}
.icon-document-csv {
  &:before {
    content: $icon-document-csv;
  }
}
.icon-downloadpng {
  &:before {
    content: $icon-downloadpng;
  }
}
.icon-download-png {
  &:before {
    content: $icon-download-png;
  }
}
.icon-document-png {
  &:before {
    content: $icon-document-png;
  }
}
.icon-downloadtxt {
  &:before {
    content: $icon-downloadtxt;
  }
}
.icon-download-txt {
  &:before {
    content: $icon-download-txt;
  }
}
.icon-document-txt {
  &:before {
    content: $icon-document-txt;
  }
}
.icon-documentppt {
  &:before {
    content: $icon-documentppt;
  }
}
.icon-document-ppt {
  &:before {
    content: $icon-document-ppt;
  }
}
.icon-download-ppt {
  &:before {
    content: $icon-download-ppt;
  }
}
.icon-document-any {
  &:before {
    content: $icon-document-any;
  }
}
.icon-documentxls {
  &:before {
    content: $icon-documentxls;
  }
}
.icon-document-xls {
  &:before {
    content: $icon-document-xls;
  }
}
.icon-download-xls {
  &:before {
    content: $icon-download-xls;
  }
}
.icon-document {
  &:before {
    content: $icon-document;
  }
}
.icon-showincolumns {
  &:before {
    content: $icon-showincolumns;
  }
}
.icon-show-in-columns {
  &:before {
    content: $icon-show-in-columns;
  }
}
.icon-showinrows {
  &:before {
    content: $icon-showinrows;
  }
}
.icon-show-in-rows {
  &:before {
    content: $icon-show-in-rows;
  }
}
.icon-removerow {
  &:before {
    content: $icon-removerow;
  }
}
.icon-remove-row {
  &:before {
    content: $icon-remove-row;
  }
}
.icon-addrow {
  &:before {
    content: $icon-addrow;
  }
}
.icon-add-row {
  &:before {
    content: $icon-add-row;
  }
}
.icon-invite {
  &:before {
    content: $icon-invite;
  }
}
.icon-add-person {
  &:before {
    content: $icon-add-person;
  }
}
.icon-add-user {
  &:before {
    content: $icon-add-user;
  }
}
.icon-kanban {
  &:before {
    content: $icon-kanban;
  }
}
.icon-detailview1 {
  &:before {
    content: $icon-detailview1;
  }
}
.icon-vertical-linechart {
  &:before {
    content: $icon-vertical-linechart;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-select {
  &:before {
    content: $icon-select;
  }
}
.icon-word-cloud {
  &:before {
    content: $icon-word-cloud;
  }
}
.icon-top-bottom {
  &:before {
    content: $icon-top-bottom;
  }
}
.icon-column-remove {
  &:before {
    content: $icon-column-remove;
  }
}
.icon-column-add {
  &:before {
    content: $icon-column-add;
  }
}
.icon-mailthanks {
  &:before {
    content: $icon-mailthanks;
  }
}
.icon-mail-thank {
  &:before {
    content: $icon-mail-thank;
  }
}
.icon-chart-bar {
  &:before {
    content: $icon-chart-bar;
  }
}
.icon-add-question {
  &:before {
    content: $icon-add-question;
  }
}
.icon-action-management {
  &:before {
    content: $icon-action-management;
  }
}
.icon-add-document {
  &:before {
    content: $icon-add-document;
  }
}
