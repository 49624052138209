$color-petrol: #009999;
$color-snow: #ffffff;
$color-stone-dark: #3c464b;
$color-stone-light: #879baa;
$color-sand-dark: #879baa;
$color-sand-light: #879baa;
$color-grey-light: #fafafa;
$color-teal-dark: #00646e;
$color-teal-light: #41aaaa;
$color-blue-dark: #005f87;
$color-blue-light: #50bed7;
$color-yellow-dark: #eb780a;
$color-yellow-light: #ffb900;
$color-red-dark: #641946;
$color-red-light: #af235f;
$color-green-dark: #647d2d;
$color-green-light: #aab414;
$color-red-badge: #ff4b2b;

$color-stone-catskill-white: #ebf0f5;
$color-stone-mystic: #dfe6ed;
$color-stone-geyser: #cdd9e1;
$color-stone-light: #879baa;
$color-stone-slate-gray: #788791;
$color-stone-pale-sky: #697882;
$color-stone-shuttle-gray: #555f69;
$color-stone-dark: #3c464b;
$color-stone-outer-space: #2d373c;
$color-stone-aztec: #0a1419;

$color-sand-pampas: #f5f5f0;
$color-sand-light35: #d7d7cd;

$color-teal-keppel: #41aaaa;
$color-teal-dark-keppel: #32a0a0;
$color-teal-jelly-bean: #239196;
$color-teal-blue-chill: #0f8287;

$color-blue-shakespeare: #50bed7;
$color-blue-eastern: #2387aa;
$color-blue-orient: #006487;
$color-blue-dark-orient: #005578;
$color-blue-prussian: #003750;

$color-yellow-light: #ffb900;
$color-yellow-sun: #faa50a;
$color-yellow-ecstasy: #f58c14;
$color-yellow-dark: #eb780a;

$color-red-mulberry: #c34673;
$color-red-maroon-flush: #af235f;

$color-purple-se: #641E8C;
$color-purple-bright:#8A00E5;
$color-purple-dark: #1B1534;