.handsontable {
  background-color: white;
  font-family: $body-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;

  .ht_clone_top {
    z-index: 0;
  }

  .border {
    box-shadow: inset 0 -2px 0 #cdd9e1;
  }

  .invalid {
    box-shadow: inset 0 -2px 0 red;
  }

  tr {
    // We had to replace this with .htDimmed and .checkbox-column because
    // otherwise the header checkbox click event was not working correctly.
    height: 2rem;
  }

  tr > .spare {
    cursor: pointer;
    padding: 0;
    pointer-events: none;

    input {
      display: none;
    }
  }

  td {
    padding: 0 1rem;
    border-top: 1px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-bottom: 1px solid $body-background-color;
    vertical-align: middle;
  }

  thead th {
    padding: 0 0.8rem;
  }

  th {
    background-color: $light-color;
    height: 2.5rem;
    vertical-align: middle;
    font-weight: 700;
    text-align: start;
    border: 0 !important;
  }

  .disabled,
  .disabled > *,
  .disabled > .htCheckboxRendererInput.noValue {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  .htCheckboxRendererInput {
    margin: 0;
  }

  .htCheckboxRendererInput.noValue {
    opacity: 1;
  }

  .checkbox-column,
  .checkbox-column > *,
  .checkbox-column > .htCheckboxRendererInput.noValue {
    cursor: pointer;
    padding: 0;

    &.hidden {
      input {
        visibility: hidden;
      }
    }
  }

  .handsontable .checker-header {
    position: absolute;
    top: -0.5rem;
    bottom: 0;
    left: -0.2rem;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hidden {
      input {
        visibility: hidden;
      }
    }

    input {
      cursor: pointer;
      margin: 0;
    }
  }
}
