@mixin scrollbar($size, $foreground-color, $background-color: mix($foreground-color, #ffffff, 50%), $rounded: false) {
  // Firefox
  scrollbar-color: $foreground-color $background-color;
  scrollbar-width: thin;

  // Chrome
  &::-webkit-scrollbar {
    height: $size;
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    @if $rounded {
      border-radius: $size;
    }
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    @if $rounded {
      border-radius: $size;
    }
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: var(--hoverPrimary02);
    }
  }

  // Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
