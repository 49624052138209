@import './quill-editor-variables';

// HTML INPUT FROM QUILL
.quill-html {
  padding: 20px 25px;
}

.ql-container {
  font-family: $body-font-family;
  font-size: $body-font-size;
}

.ql-editor {
  padding: $quill-editor-padding;
}
