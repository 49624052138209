//
// Colors
//

$primary-color: $color-teal-blue-chill !default;
$secondary-color: $color-stone-outer-space !default;
$sucess-color: $color-green-light !default;
$danger-color: $color-red-badge !default;
$warning-color: $color-yellow-ecstasy !default;
$info-color: $color-blue-orient !default;
$light-color: $color-snow !default;
$dark-color: $color-stone-outer-space !default;

$theme-colors: (
  primary: $primary-color,
  secondary: $secondary-color,
  success: $sucess-color,
  danger: $danger-color,
  warning: $warning-color,
  info: $info-color,
  light: $light-color,
  dark: $dark-color,
) !default;

//
// Logo
//
$logo-image-width: 165px !default;
$logo-image-height: 26px !default;
$logo-image-margin-top: 34px !default;
$logo-image-margin-right: 0 !default;
$logo-image-margin-bottom: 34px !default;
$logo-image-margin-left: 30px !default;

//
// Body
//

$body-background-color: $color-stone-catskill-white !default;
$body-text-color: $color-stone-outer-space !default;
$body-font-family: 'Siemens Sans', Arial, Helvetica, sans-serif !default;
$body-font-weight: normal !default;
$body-font-size: 14px !default;

//
// Links
//

$link-color: $color-stone-light !default;
$link-decoration: none !default;
$link-hover-color: $primary-color !default;
$link-hover-decoration: none !default;
$link-selected-color: $primary-color !default;
$link-selected-decoration: none !default;

//
// Buttons
//
$button-border-width: 2px !default; // 0px
$button-border-radius: 0px !default; // 0px

//
// Table
//

$table-background-color: $color-snow !default;
$table-border-color: #e6e8f2 !default;
$table-text-color: none !default;
$table-row-hover-color: #f7fafc !default;
$table-row-selected-color: $color-blue-dark !default;

//
// Inputs
//
$input-border-color: $table-border-color $table-border-color $color-stone-light $table-border-color !default;
$input-disabled-background-color: lighten($color-stone-light, 30%) !default;

//
// Text
//

$b-font-weight: 700 !default;

$h1-font-size: 32px !default;
$h1-font-weight: normal !default;
$h1-line-height: 0.78 !default;
$h1-margin: 0 0 24px 0 !default;

$h2-font-size: 26px !default;
$h2-font-weight: normal !default;
$h2-line-height: 1.1875 !default;
$h2-margin: 0 !default;

$h3-font-size: 20px !default;
$h3-font-weight: normal !default;
$h3-line-height: 1.1875 !default;
$h3-margin: 0 !default;

$h4-font-size: 18px !default;
$h4-font-weight: normal !default;
$h4-line-height: 1.1875 !default;
$h4-margin: 0 !default;

$h5-font-size: 16px !default;
$h5-font-weight: bold !default;
$h5-line-height: 1.375 !default;
$h5-margin: 0 !default;

$h-sub-color: $color-stone-slate-gray !default;
$h-sub-font-weight: 500 !default;
$h-sub-text-transform: uppercase !default;
$h-sub-font-size: 14px !default;
$h-sub-margin: 0 0 15px 0 !default;

$headlines: (
  h1: (
    font-size: $h1-font-size,
    font-weight: $h1-font-weight,
    line-height: $h1-line-height,
    margin: $h1-margin,
  ),
  h2: (
    font-size: $h2-font-size,
    font-weight: $h2-font-weight,
    line-height: $h2-line-height,
    margin: $h2-margin,
  ),
  h3: (
    font-size: $h3-font-size,
    font-weight: $h3-font-weight,
    line-height: $h3-line-height,
    margin: $h3-margin,
  ),
  h4: (
    font-size: $h4-font-size,
    font-weight: $h4-font-weight,
    line-height: $h4-line-height,
    margin: $h4-margin,
  ),
  h5: (
    font-size: $h5-font-size,
    font-weight: $h5-font-weight,
    line-height: $h5-line-height,
    margin: $h5-margin,
  ),
) !default;

//
// Focus
//
$focus-color: $link-hover-color !default;

//
// Panels
//

$panel-background-color-light: #ffffff !default;
$panel-background-color-dark: #000000 !default;
$panel-border-color: $table-border-color !default;

$form-category-background-color: $color-stone-catskill-white !default;

//
// Sidebar Navigation
//

$sidebar-nav-background-color: #000028 !default;
$sidebar-nav-text-color: $color-snow !default;
$sidebar-nav-text-hover-color: $color-stone-light !default;
$sidebar-nav-highlight-color: $color-teal-jelly-bean !default;
$sidebar-nav-border-color: #00cccc1f !default;

$sidebar-sub-nav-background-color: #f3f3f0 !default;
$sidebar-sub-nav-text-color: $color-snow !default;
$sidebar-sub-nav-text-hover-color: $color-stone-light !default;
$sidebar-sub-nav-border-color: #27253f !default;
$sidebar-sub-nav-searchbox: #d6d6d6 !default;

$sidebar-toggle-color: $color-snow !default;
$sidebar-toggle-hover-color: $link-hover-color !default;

$sidebar-decent-text-color: #7a7890 !default;
$sidebar-welcome-text-color: #00ffb9 !default;
$sidebar-username-text-color: #d7d5f1 !default;

$sidebar-item-border-color: #00ffb9 !default;

//
// Toolbar
//

$toolbar-header-background-color: #cdd9e1 !default;
$toolbar-footer-background-color: #cdd9e1 !default;

//
// Grid
//

$grid-breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

//
// Z-Index
//

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal: 1050;
$zindex-modal-backdrop: 1060;
$zindex-tooltip: 1070;

//
// Toasty
//

$toasty-background-color: $panel-background-color-light !default;
$toasty-text-color: $link-color !default;
$toasty-button-color: $link-color !default;
$toasty-button-hover-color: $link-hover-color !default;

//
// survey question
//

$question-category-hover: $color-teal-blue-chill !default;
$question-row-hover-color: #f5f5f0 !default;
$question-top-color: #5ab483 !default;
$question-bottom-color: #e85a63 !default;
$question-gauge-width: 40px;

// TODO current workaround for hiding total favorable scores from SE
$display-progress-element: flex;
$margin-progress-element: 25px;
