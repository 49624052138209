// --- UI-Kit Library Design 2021 ---

:root {
  // primary colors
  --primary01: #009999;
  --primary02: #00ffb9;
  --primary03: #000028;
  --primary04: #ffffff;

  --primary01Contrast: #ffffff;
  --primary01Lighten: #dbffff;

  // secondary colors
  --secondary01: #f0f0f0;
  --secondary02: #f3f3f0;
  --secondary03: #c5c5b8;

  // background colors
  --background01: #ffffff;
  --background02: #fbfbfb;
  --background03: #f0f0f0;

  // state colors
  --hoverPrimary01: #196269;
  --hoverPrimary02: #03d59b;
  --hoverPrimary03: #11204a;
  --hoverPrimary04: #ebebeb;
  --textPrimary01: var(--primary04);
  --textPrimary02: var(--primary03);
  --textPrimary03: var(--primary04);
  --textPrimary04: var(--primary03);

  --disabled: #d6d6d6;

  // notification colors
  --error: #ba314b;
  --success: #3a9079;
  --warning: #edbf69;

  --hoverError: #9f2b41;
  --hoverSuccess: #307462;
  --hoverWarning: #c9a258;

  // chart colors
  --chart01: #00557c;
  --chart02: #33838b;
  --chart03: #6699b0;
  --chart04: #99bbca;

  --chart10: #484848;
  --chart11: #7f7f7f;
  --chart12: #989898;
  --chart13: #b2b2b2;
  --chart14: #cccccc;

  --chart21: #ba314b;
  --chart22: #c75a6f;
  --chart23: #d58393;
  --chart24: #e3acb7;

  --chart31: #3a9079;
  --chart32: #61a693;
  --chart33: #88bcae;
  --chart34: #b0d2c9;

  --chart41: #edbf69;
  --chart42: #f0cb87;
  --chart43: #f4d8a5;
  --chart44: #f8e5c3;

  // border radius
  --borderRadius: 0px;
  --headline: 100px;

  --headline-color: #00e6dc;

  --progress-bar-height: 5px;

  --zindex-dropdown: 1000;

  --logo-image-width: 165px;
  --logo-image-height: 26px;
  --logo-image-margin-top: 0px;
  --logo-image-margin-right: 0px;
  --logo-image-margin-bottom: 0px;
  --logo-image-margin-left: 30px;

  --button-border-width: 2px;

  --link-decoration: none;
  --link-hover-decoration: none;
}
