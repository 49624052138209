$icomoon-font-family: 'pulse-icons' !default;
$icomoon-font-path: 'fonts' !default;

$icon-message: '\e900';
$icon-mic_none: '\e901';
$icon-mic_off: '\e902';
$icon-mic: '\e903';
$icon-mobile_erase: '\e904';
$icon-mobile_landscape: '\e905';
$icon-mobile_lock: '\e906';
$icon-home2: '\e907';
$icon-network: '\e908';
$icon-organization: '\e909';
$icon-projectview: '\e90a';
$icon-favorite_outline: '\e90b';
$icon-settings_multiple: '\e90c';
$icon-upgrade: '\e90d';
$icon-mobile_portrait: '\e90e';
$icon-mobile_setup: '\e90f';
$icon-mode_comment: '\e910';
$icon-euro: '\e911';
$icon-more_horizontal: '\e912';
$icon-more_vertical: '\e913';
$icon-move_to_inbox: '\e914';
$icon-gift: '\e915';
$icon-not_interested: '\e916';
$icon-notifications_none: '\e917';
$icon-notifications_off: '\e918';
$icon-notifications: '\e919';
$icon-outlined_flag: '\e91a';
$icon-pause_circle_filled: '\e91b';
$icon-company: '\e91c';
$icon-circle_outline: '\e91d';
$icon-unlock: '\e91e';
$icon-charging: '\e91f';
$icon-rates: '\e920';
$icon-gross_costs: '\e921';
$icon-quality: '\e922';
$icon-settings_single: '\e923';
$icon-savings: '\e924';
$icon-pause_circle_outline: '\e925';
$icon-tiles: '\e926';
$icon-pause: '\e927';
$icon-reset: '\e928';
$icon-person_check: '\e929';
$icon-person: '\e92a';
$icon-pie_chart: '\e92b';
$icon-play_arrow: '\e92c';
$icon-play_circle_filled: '\e92d';
$icon-play_circle_outline: '\e92e';
$icon-playlist_add_check: '\e92f';
$icon-playlist_add: '\e930';
$icon-power: '\e931';
$icon-print: '\e932';
$icon-priority_high: '\e933';
$icon-radio_button_checked: '\e934';
$icon-recent_actors: '\e935';
$icon-analyze: '\e936';
$icon-repeat_one: '\e937';
$icon-repeat: '\e938';
$icon-replay: '\e939';
$icon-save_alt: '\e93a';
$icon-clock: '\e93b';
$icon-save: '\e93c';
$icon-screen_rotation: '\e93d';
$icon-investment: '\e93e';
$icon-security: '\e93f';
$icon-question: '\e940';
$icon-quotation: '\e941';
$icon-sent: '\e942';
$icon-send: '\e942';
$icon-settings: '\e943';
$icon-share: '\e944';
$icon-show_chart: '\e945';
$icon-skip_next: '\e946';
$icon-skip_previous: '\e947';
$icon-sort: '\e948';
$icon-star_outline: '\e949';
$icon-star: '\e94a';
$icon-swap_horiz: '\e94b';
$icon-swap_vert: '\e94c';
$icon-tab: '\e94d';
$icon-text_format: '\e94e';
$icon-text_sms: '\e94f';
$icon-thumb_down_alt: '\e950';
$icon-thumb_down: '\e951';
$icon-thumb_up_alt: '\e952';
$icon-thumb_up: '\e953';
$icon-thumbs_up_down: '\e954';
$icon-today: '\e955';
$icon-toggle_off: '\e956';
$icon-toggle_on: '\e957';
$icon-translate: '\e958';
$icon-tree: '\e959';
$icon-trending_down: '\e95a';
$icon-trending_up: '\e95b';
$icon-unarchive: '\e95c';
$icon-update: '\e95d';
$icon-video_collection: '\e95e';
$icon-videocam_off: '\e95f';
$icon-videocam: '\e960';
$icon-view_list: '\e961';
$icon-view_module: '\e962';
$icon-volume_down: '\e963';
$icon-volume_mute: '\e964';
$icon-volume_off: '\e965';
$icon-volume_up: '\e966';
$icon-warning: '\e967';
$icon-workspaces: '\e968';
$icon-world: '\e969';
$icon-zoom_in: '\e96a';
$icon-zoom_out_map: '\e96b';
$icon-zoom_out: '\e96c';
$icon-account_balance: '\e96d';
$icon-account_box: '\e96e';
$icon-account_circle: '\e96f';
$icon-add_box: '\e970';
$icon-add_call: '\e971';
$icon-add_circle_outlinecontrol_point: '\e972';
$icon-add_circle: '\e973';
$icon-add_comment: '\e974';
$icon-add_to_queue: '\e975';
$icon-adduser: '\e976';
$icon-apps: '\e977';
$icon-archive: '\e978';
$icon-arrow_back: '\e979';
$icon-arrow_down: '\e97a';
$icon-arrow_forward: '\e97b';
$icon-arrow_left: '\e97c';
$icon-arrow_right: '\e97d';
$icon-arrow_up: '\e97e';
$icon-attachment: '\e97f';
$icon-backspace: '\e980';
$icon-bar_chart_1: '\e981';
$icon-bar_chart: '\e982';
$icon-block: '\e983';
$icon-bolt: '\e984';
$icon-bookmark_outlineturned_in_not: '\e985';
$icon-bookmark: '\e986';
$icon-bubble_chart: '\e987';
$icon-cake: '\e988';
$icon-calendar_empty: '\e989';
$icon-calendar: '\e98a';
$icon-call: '\e98b';
$icon-capslock: '\e98c';
$icon-chat_bubble_outline: '\e98d';
$icon-chat_bubble: '\e98e';
$icon-chat: '\e98f';
$icon-check_box_outline_blank: '\e990';
$icon-check_box: '\e991';
$icon-check: '\e992';
$icon-close: '\e993';
$icon-cloud_done: '\e994';
$icon-cloud_download: '\e995';
$icon-cloud_queue: '\e996';
$icon-cloud_upload: '\e997';
$icon-cloud: '\e998';
$icon-code: '\e999';
$icon-color_palette: '\e99a';
$icon-comment: '\e99b';
$icon-contacts: '\e99c';
$icon-content_copy: '\e99d';
$icon-content_cut: '\e99e';
$icon-createmode_editedit: '\e99f';
$icon-dashboard: '\e9a0';
$icon-data_usage: '\e9a1';
$icon-delete: '\e9a2';
$icon-description: '\e9a3';
$icon-donut_chart: '\e9a4';
$icon-drafts: '\e9a5';
$icon-error_outline: '\e9a6';
$icon-error: '\e9a7';
$icon-event_available: '\e9a8';
$icon-event_busy: '\e9a9';
$icon-file_copy: '\e9aa';
$icon-file_download: '\e9ab';
$icon-file_upload: '\e9ac';
$icon-file: '\e9ad';
$icon-filter_list_alt: '\e9ae';
$icon-filter_list: '\e9af';
$icon-find_in_page: '\e9b0';
$icon-flagassistant_photo: '\e9b1';
$icon-folder_favorite: '\e9b2';
$icon-folder_new: '\e9b3';
$icon-folder_open: '\e9b4';
$icon-folder_shared: '\e9b5';
$icon-folder: '\e9b6';
$icon-forum_answer: '\e9b7';
$icon-fullscreen_exit: '\e9b8';
$icon-fullscreen: '\e9b9';
$icon-group_add: '\e9ba';
$icon-group: '\e9bb';
$icon-help_outline: '\e9bc';
$icon-home: '\e9bd';
$icon-image: '\e9be';
$icon-import_export: '\e9bf';
$icon-inbox: '\e9c0';
$icon-info_outline: '\e9c1';
$icon-info: '\e9c2';
$icon-input: '\e9c3';
$icon-key: '\e9c4';
$icon-layers_clear: '\e9c5';
$icon-layers: '\e9c6';
$icon-library_addqueueadd_to_photos: '\e9c7';
$icon-library_books: '\e9c8';
$icon-location_off: '\e9c9';
$icon-location: '\e9ca';
$icon-loopsync: '\e9cb';
$icon-low_priority: '\e9cc';
$icon-mail_unread: '\e9cd';
$icon-menu: '\e9ce';
$icon-plus-circle: '\e9cf';
$icon-eye: '\e9d0';
$icon-check_box_indeterminate: '\e9d1';
$icon-eye-blocked: '\e9d2';
$icon-arrow_left_double: '\e9d3';
$icon-arrow_right_double: '\e9d4';
$icon-arrow_down_double: '\e9d5';
$icon-arrow_top_double: '\e9d6';
$icon-responserate: '\e9d7';
$icon-lines: '\e9d8';
$icon-detailview: '\e9d9';
$icon-lp_gruppe: '\e9da';
$icon-lp_forum: '\e9db';
$icon-lp_email: '\e9dc';
$icon-lp_dashboard: '\e9dd';
$icon-lp_checkmark_outline: '\e9de';
$icon-lp_chart_multitype: '\e9df';
$icon-lp_category: '\e9e0';
$icon-lp_bx_mobile_alt: '\e9e1';
$icon-lp_bx_layer: '\e9e2';
$icon-lp_bx_data: '\e9e3';
$icon-lp_cloud_upload: '\e9e4';
$icon-lp_cloud_download: '\e9e5';
$icon-lp_face_satisfied: '\e9e6';
$icon-lp_misuse_outline: '\e9e7';
$icon-lp_face_neutral: '\e9e8';
$icon-lp_information: '\e9e9';
$icon-lp_warning: '\e9ea';
$icon-lp_search: '\e9eb';
$icon-search: '\e9eb';
$icon-reportingline-business: '\e9ec';
$icon-reportingline-shared: '\e9ed';
$icon-reportingline-local: '\e9ee';
$icon-reportingline-governance: '\e9ef';
$icon-person-outline: '\e9f0';
$icon-fire: '\e9f1';
$icon-request: '\e9f2';
$icon-reporting: '\e9f3';
$icon-target: '\e9f4';
$icon-files: '\e9f5';
$icon-close2: '\e9f6';
$icon-bar-chart: '\e9f7';
$icon-download: '\e9f8';
$icon-check-mark: '\e9f9';
$icon-home3: '\e9fa';
$icon-info2: '\e9fb';
$icon-free: '\e9fc';
$icon-linkedin: '\e9fd';
$icon-newsletter: '\e9fe';
$icon-business: '\e9ff';
$icon-idea: '\ea00';
$icon-car: '\ea01';
$icon-building: '\ea02';
$icon-check-mark2: '\ea03';
$icon-language: '\ea04';
$icon-strategy: '\ea05';
$icon-twitter: '\ea06';
$icon-cloud-upload: '\ea07';
$icon-xing: '\ea08';
$icon-efficient: '\ea09';
$icon-money: '\ea0a';
$icon-dashboard2: '\ea0b';
$icon-burger: '\ea0c';
$icon-pulse: '\ea0d';
$icon-protected-file: '\ea0e';
$icon-back: '\ea0f';
$icon-expand-left: '\ea10';
$icon-receive: '\ea10';
$icon-expand-right: '\ea11';
$icon-analyze-active: '\ea12';
$icon-choice-grid: '\ea13';
$icon-comment2: '\ea14';
$icon-disclaimer: '\ea15';
$icon-drag: '\ea16';
$icon-duplicate: '\ea17';
$icon-edit: '\ea18';
$icon-filter: '\ea19';
$icon-hybrid-grid: '\ea1a';
$icon-information: '\ea1b';
$icon-plus: '\ea1c';
$icon-minus: '\ea1d';
$icon-pie-chart: '\ea1e';
$icon-resource-management: '\ea1f';
$icon-show: '\ea20';
$icon-sort2: '\ea21';
$icon-external-link: '\ea22';
$icon-filter-active: '\ea23';
$icon-menu1: '\ea24';
$icon-finding: '\ea25';
$icon-arrowdown: '\ea26';
$icon-arrowup: '\ea27';
$icon-downloadpdf: '\ea28';
$icon-remind: '\ea29';
$icon-inviteuser: '\ea2a';
$icon-invite-user: '\ea2a';
$icon-medium: '\ea2b';
$icon-downloadcsv: '\ea2c';
$icon-download-csv: '\ea2c';
$icon-document-csv: '\ea2c';
$icon-downloadpng: '\ea2d';
$icon-download-png: '\ea2d';
$icon-document-png: '\ea2d';
$icon-downloadtxt: '\ea2e';
$icon-download-txt: '\ea2e';
$icon-document-txt: '\ea2e';
$icon-documentppt: '\ea2f';
$icon-document-ppt: '\ea2f';
$icon-download-ppt: '\ea2f';
$icon-document-any: '\ea30';
$icon-documentxls: '\ea31';
$icon-document-xls: '\ea31';
$icon-download-xls: '\ea31';
$icon-document: '\ea32';
$icon-showincolumns: '\ea33';
$icon-show-in-columns: '\ea33';
$icon-showinrows: '\ea34';
$icon-show-in-rows: '\ea34';
$icon-removerow: '\ea35';
$icon-remove-row: '\ea35';
$icon-addrow: '\ea36';
$icon-add-row: '\ea36';
$icon-invite: '\ea37';
$icon-add-person: '\ea37';
$icon-add-user: '\ea37';
$icon-kanban: '\ea38';
$icon-detailview1: '\ea39';
$icon-vertical-linechart: '\ea3a';
$icon-lock: '\ea3b';
$icon-select: '\ea3c';
$icon-word-cloud: '\ea3d';
$icon-top-bottom: '\ea3e';
$icon-column-remove: '\ea3f';
$icon-column-add: '\ea40';
$icon-mailthanks: '\ea41';
$icon-mail-thank: '\ea41';
$icon-chart-bar: '\f128';
$icon-add-question: '\ea42';
$icon-action-management: '\ea43';
$icon-add-document: '\ea44';
